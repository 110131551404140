footer {
  background-color: black;
  color: var(--arctic-white);
  padding: 30px 0 30px 0;
}

.social-icon {
  width: 30px;
  height: 30px;
}

.social-icon:hover {
  filter: brightness(1.25);
}

.socials-list {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 20px;
  list-style: none;
  text-align: center;
  justify-content: center;
  padding-top: 20px;
}

.copyright {
  text-align: center;
  margin-top: 10px;
}

.powered-by {
  white-space: normal;
  text-align: center;
  margin-top: 10px;
  font-weight: 300;
  font-size: 0.9rem;
}

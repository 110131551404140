.navbar-items {
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  min-height: 50px;
}

.nav-scrolled {
  position: fixed;
  background-color: black;
  opacity: 0.7;
  width: 100%;
  height: 8vh;
  min-height: 50px;
  z-index: 1;
  transition: all 0.5s ease;
}

.navbar-logo {
  color: var(--arctic-white);
  justify-self: start;
  margin-left: 20px;
  width: 200px;
  font-weight: 500;
  position: fixed;
  left: 5vw;
  z-index: 2;
}

.navbar-logo:hover {
  filter: brightness(1.5);
  transition: all 0.3s ease-out;
}

a {
  text-decoration: none;
  color: var(--arctic-white);
}

.nav-menu {
  position: fixed;
  right: 6vw;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 30px;
  list-style: none;
  text-align: center;
  justify-content: end;
  z-index: 2;
}

.nav-links {
  padding: 0.5rem 1rem;
  font-weight: 400;
}

.nav-links:hover {
  filter: brightness(1.25);
  transition: all 0.3s ease-out;
}

.login {
  background-color: var(--phosphor-green);
  border-radius: 10px;
}

.fa-bars {
  color: var(--arctic-white);
}

.fa-times {
  color: var(--arctic-white);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 263px;
    position: fixed;
    top: 8vh;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    grid-gap: 0px;
    z-index: 2;
  }

  .nav-menu.active {
    background: var(--phosphor-green);
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background: var(--phosphor-green);
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    font-size: 1.5rem;
    position: fixed;
    top: 4vh;
    right: 40px;
    transform: translateY(-50%);
    padding: 1rem;
    opacity: 1;
    z-index: 2;
  }
}

@media screen and (max-height: 700px) {
  .menu-icon {
    top: 25px;
  }
}

.contact-info {
  position: absolute;
  top: 20%;
  left: 10%;
}

.contact-form {
  width: 40%;
  height: 450px;
  position: absolute;
  top: 15%;
  left: 50%;
  text-align: center;
  color: var(--arctic-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-card {
  margin-bottom: 50px;
  list-style: none;
  display: flex;
}

.info-card i {
  width: 60px;
  height: 60px;
  margin-right: 30px;
  margin-top: 10px;
  background: var(--phosphor-green);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
  color: var(--arctic-white);
}

.contact-form textarea,
input {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  border: none;
  padding-left: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
  /* background-color: var(--arctic-white); */
}

.contact-form input[type="text"]:focus,
textarea:focus {
  background-color: var(--arctic-white);
  outline: 3px solid var(--phosphor-green);
}
.contact-form input[type="text"]:hover,
textarea:hover {
  background-color: var(--arctic-white);
}

.contact-form input {
  height: 40px;
}

.contact-form textarea {
  resize: none;
  height: 180px;
  padding-top: 5px;
}

.contact-form div {
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
}

.contact-form h1 {
  margin-bottom: 20px;
}

.submit-contact {
  border: none;
  height: 50px;
  background-color: rgb(67, 151, 102);
  border-radius: 50px;
  margin-top: 20px;
  color: var(--arctic-white);
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
}

.submit-contact:hover {
  filter: brightness(1.25);
  transition: all 0.2s ease;
}

.title-slide-mobile-expand {
  width: 100%;
  min-height: 600px;
  height: 92vh;
  color: var(--arctic-white);
  position: relative;
  max-width: 1800px;
  margin: auto;
}

@media screen and (max-width: 850px) {
  .title-slide-mobile-expand {
    height: 1000px;
  }

  .contact-info {
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
  }

  .contact-form {
    top: 480px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .contact-form {
    width: 80%;
  }
}

iframe {
  width: 100%;
  height: 350px;
  border: none;
}

.contact-message {
  color: rgb(0, 213, 255);
}

.small-cards-list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  position: relative;
}

.small-card-icon {
  width: 80px;
  height: 80px;
}

.small-card {
  width: 300px;
  height: 300px;
  background-color: var(--arctic-white);
  border: 1px solid var(--grey-text);
  padding: 30px;
  border-radius: 20px;
  transition: all 0.2s ease;
}

.small-card:hover {
  border-bottom: 6px solid var(--phosphor-green);
}

.small-card:hover .small-card-title {
  color: var(--phosphor-green);
}

.small-card-title {
  font-size: 1.4rem;

  margin-bottom: 5px;
}

.small-card-description {
  line-height: 1.3;
  text-align: left;
}

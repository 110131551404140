:root {
  --dashboard-navbar-bg: #272727;
}

aside {
  width: 15%;
  min-height: 100vh;
  background-color: var(--dashboard-navbar-bg);
  color: var(--arctic-white);
  position: relative;
  transition: all 0.3s ease;
  z-index: 1;
}

.active {
  transform: translateX(0%);
  display: block;
}

aside div div i {
  font-size: 80px;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user-icon {
  background-color: var(--phosphor-green);
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  overflow: hidden;
}

.user-info {
  text-align: center;
  padding: 0 5% 0 5%;
}

.user-info p {
  margin-top: 80px;
  font-size: 0.9rem;
  word-break: break-word;
}

.dashboard-button {
  height: 30px;
  width: 90%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid var(--phosphor-green);
  background-color: var(--dashboard-navbar-bg);
  border-radius: 20px;
  color: var(--phosphor-green);
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.dashboard-button:hover {
  cursor: pointer;
  background-color: var(--phosphor-green);
  color: var(--arctic-white);
}

.logout {
  bottom: 30px;
}

.home {
  bottom: 75px;
}

@media screen and (max-width: 1200px) {
  aside {
    width: 20%;
  }
}

@media screen and (max-width: 1000px) {
  aside {
    width: 25%;
  }
}

@media screen and (max-width: 768px) {
  aside {
    position: fixed;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    padding-top: 8vh;
    transform: translateX(-100%);
    display: none;
  }
  .dashboard-button {
    width: 60%;
  }
  #dashboard-nav {
    display: inline-block;
  }
}

.dashboard-nav {
  position: fixed;
  background-color: var(--dashboard-navbar-bg);
  width: 100%;
  color: var(--arctic-white);
  height: 8vh;
  min-height: 50px;
  max-height: 70px;
  display: none;
  z-index: 2;
  /* top: 0%; */
}

.dashboard-nav h1,
.dashboard-nav i {
  margin: auto 0 auto 0;
  font-size: 1.4rem;
  position: absolute;
  transform: translateY(-50%);
}

.dashboard-nav h1 {
  left: 10%;
  top: 50%;
}

.dashboard-nav i {
  top: 50%;
  right: 10%;
}

.dashboard-nav i:hover,
.dashboard-nav h1:hover {
  filter: brightness(1.25);
}

.trap-list {
  margin-top: 30px;
  margin-bottom: 150px;
  text-align: center;
}

.trap-list ul li {
  list-style: none;
  margin-bottom: 10px;
}

.trap-list ul li:hover {
  list-style: none;
  filter: brightness(1.25);
  cursor: pointer;
}

.trap-list .add-trap {
  color: var(--phosphor-green);
  transition: all 0.3s ease;
}

.trap-list .add-trap:hover {
  filter: brightness(1.25);
  cursor: pointer;
}

.trap-active {
  /* color: var(--phosphor-green); */
  color: rgb(0, 213, 255);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;700&display=swap");

:root {
  --arctic-white: #e7e6eb;
  /* --phosphor-green: rgb(5, 92, 5); */
  --phosphor-green: #439766;
  --grey-text: rgb(70, 70, 70);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  background: url("./assets/home-page-images/homepage.png");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.title-slide {
  width: 100%;
  min-height: 100vh;
  color: var(--arctic-white);
  position: relative;
}

.title {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.login-box {
  display: block;
  color: black;
  width: 400px;
  border-radius: 20px;
  transition: all 0.2s ease;
}

.login-box p {
  color: var(--arctic-white);
}

.login-box p:hover a {
  color: var(--phosphor-green);
}

.login-input {
  position: left;
  background-position-y: center;
  background-position-x: 5px;
  padding-left: 45px;
  background-size: 30px;
  background-repeat: no-repeat;
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.5);
}

.usrnm {
  background-image: url("./assets/login-page-icons/user-svgrepo-com.svg");
}

.pswd {
  background-image: url("./assets/login-page-icons/lock-svgrepo-com.svg");
}

.trap-name {
  background-image: url("./assets/login-page-icons/tag-svgrepo-com.svg");
}

.trap-id {
  background-image: url("./assets/login-page-icons/key-svgrepo-com.svg");
}

.login-submit {
  width: 100%;
  border: none;
  height: 50px;
  background-color: rgba(67, 151, 102, 0.7);
  border-radius: 50px;
  margin-top: 20px;
  color: var(--arctic-white);
  font-size: 1.1rem;
  font-weight: 500;
}

.login-submit:hover {
  background-color: rgb(67, 151, 102);
  transition: all 0.2s ease;
  cursor: pointer;
}

.home-heading {
  text-transform: uppercase;
  font-size: 2.8rem;
  font-weight: 600;
  min-width: 400px;
  line-height: 1.2;
  padding-bottom: 30px;
}

.login-title {
  color: var(--arctic-white);
  text-transform: capitalize;
  margin-bottom: 25px;
}

.title h2 {
  font-weight: 400;
  font-size: 1.2rem;
}

.content {
  padding-bottom: 50px;
  margin: 0;
  padding: 0;
  background: var(--arctic-white);
}

.phosphor-green {
  color: var(--phosphor-green);
}

.translate-button {
  position: fixed;
  right: 3px;
  bottom: 0;
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background-color: transparent;
  z-index: 2;
  transition: all 0.3s ease;
  cursor: pointer;
}

.translate-button img {
  width: 25px;
  height: 25px;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.translate-button:hover img {
  width: 35px;
}

.error {
  color: rgb(224, 2, 2);
}

.success {
  color: rgb(0, 213, 255);
}

.confirm-email-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--arctic-white);
  width: 50%;
  border-radius: 30px;
  color: var(--grey-text);
  text-align: center;
  padding: 30px;
}

.confirm-email-container h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.confirm-email-container .prim-button {
  width: 60%;
  padding: 10px 0 10px 0;
  margin-bottom: 20px;
  border: none;
  background-color: var(--phosphor-green);
  border-radius: 30px;
  color: var(--arctic-white);
  transition: all 0.3s ease;
}

.confirm-email-container .prim-button:hover {
  filter: brightness(1.25);
  cursor: pointer;
}

.info {
  color: rgb(60, 60, 204);
  margin-bottom: 20px;
}

.sec-btn {
  width: 150px;
  padding: 10px 0 10px 0;
  background-color: var(--arctic-white);
  border: 1px solid var(--phosphor-green);
  border-radius: 30px;
  color: var(--phosphor-green);
  transition: all 0.3s ease;
  margin-top: 20px;
}

.sec-btn:hover {
  color: var(--arctic-white);
  background-color: var(--phosphor-green);
}

.dashboard {
  display: flex;
}

@media screen and (max-width: 768px) {
  .confirm-email-container {
    width: 90%;
  }
  .dashboard {
    display: block;
  }
}

.file {
  background-image: url("./assets/login-page-icons/file-svgrepo-com.svg");
  padding-top: 1rem;
  padding-left: 55px;
}

.reset-password {
  margin-top: 10px;
}

.reset-password:hover {
  color: var(--phosphor-green);
  cursor: pointer;
}
